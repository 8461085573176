<template>
  <div class="container">
    <Loader v-bind:showLoader="loading" />

    <b-tabs>
      <b-tab title="Параметры врача" >
        <b-form @submit.prevent="onSubmit">
          <h3 v-if="doctorId">Редактирование врача</h3>
          <h3 v-else>Создание врача</h3>
          <div v-if="error" class="alert alert-danger">
            {{error}}
          </div>
          <div v-if="doctorId" class="row" style="border: gray 1px solid; border-bottom-style: none;">
            <div  class="col-2 p-2 ">Сессий работы с системой</div>
            <div  class="col-2 p-1"><b-form-input type="text" v-model="doctorSessionInfo.sessionCount" readonly disabled></b-form-input></div>
            <div  class="col-2">&nbsp;</div>
            <div  class="col-2 p-2 ">Дата последней сессии</div>
            <div  class="col-2 p-2">{{ new Intl.DateTimeFormat('ru-RU').format(doctorSessionInfo.lastSessionDate) }}</div>
            <div  class="col-2">&nbsp;</div>
          </div>
          <div v-if="doctorId" class="row" style="border: gray 1px solid; border-top-style: none;">
            <div  class="col-2 p-2 ">Количество пациентов</div>
            <div  class="col-1 p-1"><b-form-input type="text" v-model="doctorSessionInfo.patientCount" readonly disabled></b-form-input></div>
            <div  class="col-1">&nbsp;</div>
            <div  class="col-2 p-2 ">Количество отчетов</div>
            <div  class="col-1 p-1"><b-form-input type="text" v-model="doctorSessionInfo.reportCount" readonly disabled></b-form-input></div>
            <div  class="col-1">&nbsp;</div>
            <div  class="col-2 p-2 ">Количество аналитов</div>
            <div  class="col-1 p-1"><b-form-input type="text" v-model="doctorSessionInfo.paramCount" readonly disabled></b-form-input></div>
            <div  class="col-1">&nbsp;</div>
          </div>
          <br>
          <b-form-group id="email" label-for="email" label="Email">
            <b-form-input id="email" type="text" placeholder="" required v-model="doctor.email" autocomplete="off"></b-form-input>
          </b-form-group>
          <b-form-group v-if="!doctorId" id="password" label-for="password" label="Пароль">
            <b-form-input id="password" type="password" required placeholder="Введите пароль" v-model="doctor.password" autocomplete="off"></b-form-input>
          </b-form-group>
          <b-form-group id="firstname" label-for="firstname" label="Имя">
            <b-form-input id="firstname" type="text" placeholder="Например, Иван" required v-model="doctor.firstname" autocomplete="off"></b-form-input>
          </b-form-group>
          <b-form-group id="middlename" label-for="middlename" label="Отчество">
            <b-form-input id="middlename" type="text" placeholder="Например, Иванович" v-model="doctor.middlename" autocomplete="off"></b-form-input>
          </b-form-group>
          <b-form-group id="lastname" label-for="lastname" label="Фамилия">
            <b-form-input id="lastname" type="text" placeholder="Например, Иванов" required v-model="doctor.lastname" autocomplete="off"></b-form-input>
          </b-form-group>
          <b-form-group id="activationDate" label-for="activationDate" label="Дата активации">
            <!--        <b-input-group>-->
            <!--                  <b-input-group-prepend>-->
            <!--                    <b-form-datepicker-->
            <!--                        v-model="doctor.activationDate"-->
            <!--                        button-only-->
            <!--                        left-->
            <!--                        locale="ru"-->
            <!--                        aria-controls="example-input"-->
            <!--                        @context="onContext"-->
            <!--                    ></b-form-datepicker>-->
            <!--                  </b-input-group-prepend>-->
            <!--                  <b-form-input-->
            <!--              id="example-input"-->
            <!--              v-mask="'####-##-##'"-->
            <!--              v-model="doctor.activationDate"-->
            <!--              type="text"-->
            <!--              placeholder="ДД.ММ.ГГГГ"-->
            <!--              autocomplete="off"-->
            <!--          ></b-form-input>-->

            <!--        </b-input-group>-->


            <b-form-input id="activationDate" type="date" placeholder="" v-model="doctor.activationDate" autocomplete="off"></b-form-input>
            <!--        <b-form-datepicker-->
            <!--            id="activationDate"-->
            <!--            v-model="doctor.activationDate"-->
            <!--            autocomplete="off"-->
            <!--            locale="ru"-->
            <!--        ></b-form-datepicker>-->
            <!--                    <date-picker type="date" v-mask="'##.##.####'" v-model="doctor.activationDate" value-type="YYYY-MM-DD" format="DD.MM.YYYY">-->

            <!--                    </date-picker>-->
          </b-form-group>
          <b-form-group id="activationDaysCount" label-for="activationDaysCount" label="Срок активации (дней)">
            <b-form-input id="activationDaysCount" type="number" placeholder="" v-model="doctor.activationDaysCount" autocomplete="off"></b-form-input>
          </b-form-group>

          <!--      <div class="mb-3">-->
          <!--        <label for="phoneNumber" class="form-label">Номер телефона</label>-->
          <!--        <input type="text" id="phoneNumber" v-mask="'+ # (###) ###-##-##'"  placeholder="Например, 79991234567" v-model="doctor.phoneNumber" autocomplete="off">-->
          <!--      </div>-->
          <!--      <input v-model="doctor.phoneNumber" autocomplete="off" v-mask="vMask"  placeholder="+ 7 (999) 999-99-99"  class="edit-container__input-text">-->

          <b-form-group id="phoneNumber" label-for="phoneNumber" label="Номер телефона">
            <b-form-input id="phoneNumber"  type="text" placeholder="Например, 79991234567" v-mask="vMask" v-model="doctor.phoneNumber" autocomplete="off"></b-form-input>
          </b-form-group>
          <!--      <b-form-group id="genderCode" label-for="genderCode" label="Пол">-->
          <!--        <b-form-select id="genderCode" v-model="doctor.genderCode" :options="genderOptions" :select-size="genderOptions.length"></b-form-select>-->
          <!--      </b-form-group>-->
          <b-form-group id="regionCode" label-for="regionCode" label="Регион">
            <b-form-select id="regionCode" v-model="doctor.regionCode" :options="regionOptions | mainRussiaRegions" :select-size="1"></b-form-select>
          </b-form-group>
          <b-form-group id="doctorComment" label-for="doctorComment" label="Комментарий">
            <b-form-textarea
                id="doctorComment"
                v-model="doctor.doctorComment"
                rows="2"
                max-rows="2"
            ></b-form-textarea>
            <!--        <ckeditor class="ck-content" :editor="editor" v-model="doctor.doctorComment"-->
            <!--                  :config="editorConfig"></ckeditor>-->
          </b-form-group>


          <b-form-group id="clinics" label-for="clinics" label="Клиники">
            <b-button variant="info" @click="addClinic()">Создать клинику</b-button><br>
            <treeselect name="clinics" id="clinics" placeholder=""
                        multiple clearable searchable
                        open-on-click disableFuzzyMatching
                        :noResultsText="'Не найдено...'"
                        :options="clinicOptions"
                        :max-height="200"
                        :normalizer="normalizeClinics"
                        v-model="clinics" />
          </b-form-group>

          <b-form-group id="specializations" label-for="specializations" label="Специализации">
            <b-button variant="info" @click="addSpecialization()">Создать специализацию</b-button><br>
            <treeselect name="specializations" id="specializations" placeholder=""
                        multiple clearable searchable
                        open-on-click disableFuzzyMatching
                        :noResultsText="'Не найдено...'"
                        :options="specializationOptions"
                        :max-height="200"
                        :normalizer="normalizeSpecializations"
                        v-model="specializations" />
          </b-form-group>

          <b-button variant="info" type="submit" >Сохранить</b-button>
          &nbsp;
          <b-button variant="outline-info" @click="clickBackButton" >Назад</b-button>
        </b-form>


      </b-tab>
      <b-tab v-if="doctorId" title="Настройка доступа к программе">
        <b-row>
<!--          <div class="custom-modal-header w-100 mb-2">-->
<!--            <div class="modal-title ">Настройка интерпретации результатов исследований</div>-->
<!--            <div class="modal-close justify-content-end"><b-link @click="hideReportSettings" ><img src="@/assets/images/patients/icon_delete.png" title="Закрыть окно"></b-link></div>-->
<!--          </div>-->
          <div class="container-fluid settings-modal">
            <div class="row pt-4">
              <div class="col">
                <span class="report-settings-title d-flex text-uppercase">
                  Настройки доступа врача. Редактируются администратором.
                </span>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col">
                <span class="report-settings-title d-flex">
                  Управление настройками доступа
                </span>
                <div class="d-flex pt-3">
                  <div class="col-6">
                    <b-form-checkbox id="reportDetailedBlock" switch @change="changeReportDetailedBlock" v-model="doctor.reportDetailedBlock">Заблокировать подробный отчёт
                    </b-form-checkbox>
                  </div>
                  <div class="col-6 pl-4">
                    <b-form-checkbox id="reportGeneralBlock" switch @change="changeReportGeneralBlock" v-model="doctor.reportGeneralBlock">Заблокировать обобщенный отчёт
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="d-flex pt-3">
                  <div class="col-6">
                    <b-form-checkbox id="reportConciseBlock" switch @change="changeReportConciseBlock" v-model="doctor.reportConciseBlock">Заблокировать сокращенный отчёт
                    </b-form-checkbox>
                  </div>
                  <div class="col-6 pl-4">
                    <b-form-checkbox id="reportPatientBlock" switch @change="changeReportPatientBlock" v-model="doctor.reportPatientBlock">Заблокировать отчёт пациента
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="d-flex pt-3">
                  <div class="col-6">
                    <b-form-checkbox id="reportGraphicBlock" switch @change="changeReportGraphicBlock" v-model="doctor.reportGraphicBlock">Заблокировать графический отчёт
                    </b-form-checkbox>
                  </div>
                  <div class="col-6 pl-4">
                    <b-form-checkbox id="reportPreBlock" switch @change="changeReportPreBlock" v-model="doctor.reportPreBlock">Заблокировать предварительный отчёт
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="d-flex pt-3">
                  <div class="col-6">
                    <b-form-checkbox id="paramPrescribedBlock" switch @change="changeParamPrescribedBlock" v-model="doctor.paramPrescribedBlock">Убрать страницу "Назначения"
                    </b-form-checkbox>
                  </div>
                  <div class="col-6 pl-4">
                    <b-form-checkbox id="reportConfigBlock" switch @change="changeReportConfigBlock" v-model="doctor.reportConfigBlock">Скрыть кнопку "Настройка отчетов" на странице "Отчеты"
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="d-flex pt-3">
                  <div class="col-6">
                    <b-form-checkbox id="alternativeScaleBlock" switch @change="changeAlternativeScaleBlock" v-model="doctor.alternativeScaleBlock">Заблокировать "Международные рекомендации"
                    </b-form-checkbox>
                  </div>
                  <div class="col-6 pl-4">
                    <b-form-checkbox id="onlyOMSParams" switch @change="changeOnlyOMSParams" v-model="doctor.onlyOMSParams">Только аналиты, Приказ МЗ РФ №804
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="d-flex pt-3">
                  <div class="col-6">
                    <b-form-checkbox id="debugDoctor" switch @change="changeDebugDoctor" v-model="doctor.debugDoctor">Доступ к служебным аналитам и разным шаблонам
                    </b-form-checkbox>
                  </div>
                  <div class="col-6 pl-4">
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>

            <div class="row pt-2">
              <div class="col">
                <span class="report-settings-title d-flex">
                  Доступ к данным анкеты
                </span>
                <div class="d-flex pt-3">
                  <div class="col-6">
                    <b-form-checkbox id="questionaryBlock" switch @change="changeQuestionaryBlock" v-model="doctor.questionaryBlock">Убрать страницу "Анкета пациента"
                    </b-form-checkbox>
                  </div>
                  <div class="col-6 pl-4">
                    &nbsp;
                  </div>
                </div>
                <div class="d-flex pt-3">
                  <div class="col-6">
                    <b-form-checkbox id="questionaryTabGeneralEnable" switch @change="changeQuestionaryTabGeneralEnable" v-model="doctor.questionaryTabGeneralEnable">Доступ к вкладке "Общая часть"
                    </b-form-checkbox>
                  </div>
                  <div class="col-6 pl-4">
                    <b-form-checkbox id="questionaryTabReproductEnable" switch @change="changeQuestionaryTabReproductEnable" v-model="doctor.questionaryTabReproductEnable">Доступ к вкладке "Репродуктивное здоровье"
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="d-flex pt-3">
                  <div class="col-6">
                    <b-form-checkbox id="questionaryTabComplaintsEnable" switch @change="changeQuestionaryTabComplaintsEnable" v-model="doctor.questionaryTabComplaintsEnable">Доступ к вкладке "Жалобы"
                    </b-form-checkbox>
                  </div>
                  <div class="col-6 pl-4">
                    <b-form-checkbox id="questionaryTabChronicEnable" switch @change="changeQuestionaryTabChronicEnable" v-model="doctor.questionaryTabChronicEnable">Доступ к вкладке "Хронические заболевания"
                    </b-form-checkbox>
                  </div>
                </div>
                <div class="d-flex pt-3">
                  <div class="col-6">
                    <b-form-checkbox id="questionaryTabDrugEnable" switch @change="changeQuestionaryTabDrugEnable" v-model="doctor.questionaryTabDrugEnable">Доступ к вкладке "Лекарства"
                    </b-form-checkbox>
                  </div>
                  <div class="col-6 pl-4">
                    <b-form-checkbox id="questionaryTabHeredityEnable" switch @change="changeQuestionaryTabHeredityEnable" v-model="doctor.questionaryTabHeredityEnable">Доступ к вкладке "Наследственность"
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col">
              <span class="report-settings-title d-flex">
                  Рекомендации
                </span>

                <div class="d-flex pt-3 col">
                  <div>
                    <div class="radio-item">
                      <b-checkbox switch v-model="doctor.reportParamRecommendBlock" id="reportParamRecommendBlock" name="reportParamRecommendBlock" @change="toogleReportParamRecommendBlock" ></b-checkbox>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label for="reportParamRecommendBlock" class="pl-2">
                      <span class="report-settings-element" >Выключить рекомендации в отчетах
                      </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="d-flex pt-4 col">
                  <div>
                    <div class="radio-item">
                      <b-checkbox switch v-model="doctor.reportParamRecommendMetabolomicBlock" id="reportParamRecommendMetabolomicBlock" name="reportParamRecommendMetabolomicBlock" @change="toogleReportParamRecommendMetabolomicBlock"></b-checkbox>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label for="reportParamRecommendMetabolomicBlock" class="pl-2">
                      <span class="report-settings-element" >Исключить метаболомные аналиты из рекомендаций отчетов
                      </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div class="row pt-4">
              <div class="col">
                <span class="report-settings-title d-flex text-uppercase">
                  Настройки отчетов. Редактируются администратором и врачом.
                </span>
              </div>
            </div>

            <div class="row pt-2">
              <div class="col">
                <span class="report-settings-title d-flex">
                  Баллы
                </span>
                <div class="d-flex pt-3">
                  <div class="col-6">
                    <b-form-checkbox id="showCoefDReportGeneral" switch @change="changeShowCoefDReportGeneral" v-model="doctor.showCoefDReportGeneral">Включить баллы в обобщенном отчете
                    </b-form-checkbox>
                  </div>
                  <div class="col-6 pl-4">
                    <b-form-checkbox id="showCoefDReportConcise" switch @change="changeShowCoefDReportConcise" v-model="doctor.showCoefDReportConcise">Включить баллы в сокращенном отчете
                    </b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>


            <div class="row pt-2">
              <div class="col">
              <span class="report-settings-title d-flex">
                Управление детализацией отчета
              </span>
                <div class="d-flex pt-3">
                  <div>
                    <div class="radio-item">
                      <b-checkbox switch id="excludeSeldomDiseaseCheckbox" name="excludeSeldomDiseaseCheckbox" v-model="clientReport.excludeSeldomDisease"></b-checkbox>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label for="excludeSeldomDiseaseCheckbox" class="pl-2">
                      <span class="report-settings-element" >Исключить из отчета редкие нозологические формы
                      </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="d-flex pt-4">
                  <div>
                    <div class="radio-item">
                      <b-checkbox switch id="excludePriorityDiseaseCheckbox" name="excludePriorityDiseaseCheckbox" v-model="clientReport.excludePriorityDisease"></b-checkbox>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label for="excludePriorityDiseaseCheckbox" class="pl-2">
                      <span class="report-settings-element" >Исключить из отчета нозологические формы, имеющие неспецифическую связь с отклонениями аналитов
                      </span>
                      </label>
                    </div>
                  </div>
                </div>

                <span class="report-settings-title d-flex">
                Управление рекомендациями в отчете
              </span>
                <div class="d-flex pt-3">
                  <div>
                    <div class="radio-item">
                      <b-checkbox switch v-model="clientReport.excludeBlackParam" id="excludeBlackParam" name="excludeBlackParam" @change="toogleExcludeBlackParam" ></b-checkbox>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label for="excludeBlackParam" class="pl-2">
                      <span class="report-settings-element" >Исключить детализирующие аналиты (черный цвет)
                      </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="d-flex pt-4">
                  <div>
                    <div class="radio-item">
                      <b-checkbox switch v-model="clientReport.excludeBlackGreenParam" id="excludeBlackGreenParam" name="excludeBlackGreenParam" @change="toogleExcludeBlackGreenParam" ></b-checkbox>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label for="excludeBlackGreenParam" class="pl-2">
                      <span class="report-settings-element" >Исключить уточняющие и детализирующие аналиты (черный и зеленый цвета)
                      </span>
                      </label>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col">
              <span class="report-settings-title d-flex">
                  Уровень отчета
              </span>
                <div class="d-flex pt-3">
                  <div>
                    <div class="radio-item">
                      <input type="radio" value="1" id="reportLevelRadio1" name="reportLevelRadio1" v-model="clientReport.reportLevel" />
                      <label for="reportLevelRadio1"></label>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label for="reportLevelRadio1" class="pl-2">
                        <span class="report-settings-element" >С детализацией нозологических форм, особых состояний и интерференций</span>
                      </label>
                    </div>
                    <div class="pl-2">
                      <div class="report-settings-subtext">
                        Дифференциальная диагностика с детализацией  нозологических форм и патологических состояний;
                        предметное распознавание особых состояний и факторов внешней среды;
                        исключение влияния конкретных лекарственных препаратов;
                        детализированное исключение лабораторных ошибок и интерференций.

                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex pt-1">
                  <div>
                    <div class="radio-item">
                      <input type="radio" value="2" id="reportLevelRadio2" name="reportLevelRadio2" v-model="clientReport.reportLevel" />
                      <label for="reportLevelRadio2"></label>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label for="reportLevelRadio2" class="pl-2">
                        <span class="report-settings-element" >Без детализации нозологических форм, особых состояний и интерференций</span>
                      </label>
                    </div>
                    <div class="pl-2">
                      <div class="report-settings-subtext">
                        Дифференциальная диагностика без детализации нозологических форм и патологических состояний;
                        групповое распознавание особых состояний и факторов внешней среды;
                        исключение влияния лекарственных препаратов на уровне фармакологических групп;
                        обобщённое исключение лабораторных ошибок и интерференций.

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
<span class="report-settings-title d-flex">
                Вид части отчета по влиянию лекарственных препаратов на аналит
              </span>
                <div class="d-flex pt-3">
                  <div>
                    <div class="radio-item">
                      <input type="radio" value="1" id="reportViewRadio1" name="reportViewRadio1" v-model="clientReport.reportView" />
                      <label for="reportViewRadio1"></label>
                    </div>
                  </div>
                  <div>
                    <label for="reportViewRadio1" class="pl-2">
                    <span class="report-settings-element" >Включить в отчет все лекарственные препараты
                    </span>
                    </label>
                  </div>
                </div>
                <div class="d-flex pt-1">
                  <div>
                    <div class="radio-item">
                      <input type="radio" value="2" id="reportViewRadio2" name="reportViewRadio2" v-model="clientReport.reportView" />
                      <label for="reportViewRadio2"></label>
                    </div>
                  </div>
                  <div>
                    <label for="reportViewRadio2" class="pl-2">
                    <span class="report-settings-element" >Включить в отчет только принимаемые пациентом лекарственные препараты
                    </span>
                    </label>
                  </div>
                </div>
                <div class="d-flex pt-1">
                  <div>
                    <div class="radio-item">
                      <input type="radio" value="3" id="reportViewRadio3" name="reportViewRadio3" v-model="clientReport.reportView" />
                      <label for="reportViewRadio3"></label>
                    </div>
                  </div>
                  <div>
                    <label for="reportViewRadio3" class="pl-2">
                    <span class="report-settings-element" >Включить в отчет все фармакологические группы
                    </span>
                    </label>
                  </div>
                </div>
                <div class="d-flex pt-1">
                  <div>
                    <div class="radio-item">
                      <input type="radio" value="4" id="reportViewRadio4" name="reportViewRadio4" v-model="clientReport.reportView" />
                      <label for="reportViewRadio4"></label>
                    </div>
                  </div>
                  <div>
                    <label for="reportViewRadio4" class="pl-2">
                    <span class="report-settings-element" >Включить в отчет только те фармакологические группы,
                      в которые входит принимаемый пациентом лекарственный препарат
                    </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
              <span class="report-settings-title d-flex">
                Управление частотно-вероятностными событиями для обобщенных и сокращенных отчетов:
                нозологические формы, особые состояния, факторы внешней среды, лекарственные препараты и лабораторные ошибки
              </span>
                <div class="d-flex pt-3 col">
                  <div>
                    <div class="radio-item">
                      <b-checkbox switch v-model="clientReport.excludeLowProbabilityEvents" id="excludeLowProbabilityEvents" name="excludeLowProbabilityEvents" @change="toogleExcludeLowProbabilityEvents" ></b-checkbox>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label for="excludeLowProbabilityEvents" class="pl-2">
                      <span class="report-settings-element" >Исключить низковероятные события (черный цвет)
                      </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="d-flex pt-4 col">
                  <div>
                    <div class="radio-item">
                      <b-checkbox switch v-model="clientReport.excludeMiddleProbabilityEvents" id="excludeMiddleProbabilityEvents" name="excludeMiddleProbabilityEvents" @change="toogleExcludeMiddleProbabilityEvents"></b-checkbox>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label for="excludeMiddleProbabilityEvents" class="pl-2">
                      <span class="report-settings-element" >Исключить низковероятные  и умеренно вероятные события (черный и зеленый цвета)
                      </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
              <span class="report-settings-title d-flex">
                  Включение в отчет классов нозологических форм на основе МКБ
                </span>

                <div class="d-flex pt-4 col-12">
                  <div>
                    <div class="radio-item">
                      <b-checkbox switch id="allDiseasesPCCCheckbox" name="allDiseasesPCCCheckbox" v-model="clientReport.allDisease"></b-checkbox>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label for="allDiseasesPCCCheckbox" class="pl-2">
                                          <span class="report-settings-element" >Все классы нозологических форм
                                          </span>
                      </label>
                    </div>
                  </div>
                </div>


                <div v-if="!clientReport.allDisease" class="d-flex pt-4">
                  <div>
                    <div class="radio-item">
                    </div>
                  </div>
                  <div>
                    <div>
                      <label for="reportTypeRadio3" class="pl-2">
                                          <span class="report-settings-element" >
                            <b-form-checkbox-group switches style="column-count: 3" v-model="diseasePccList" :options="diseasePccListOptions" @change="changeDiseaseRcc"></b-form-checkbox-group>
                                          </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col">
              <span class="report-settings-title d-flex">
                  Исключение анкеты
                </span>

                <div class="d-flex pt-4 col-12">
                  <div>
                    <div class="radio-item">
                      <b-checkbox switch id="excludeCheckListDiseasePCCCheckbox" name="excludeCheckListDiseasePCCCheckbox" v-model="clientReport.excludeCheckListDisease"></b-checkbox>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label for="excludeCheckListDiseasePCCCheckbox" class="pl-2">
                                          <span class="report-settings-element" >Не учитывать анкету в отчетах
                                          </span>
                      </label>
                    </div>
                  </div>
                </div>



              </div>
            </div>



          </div>

        </b-row>
      </b-tab>
      <b-tab title="Шаблоны отчетов">
        <b-form-group id="reportDetailedSubType" label-for="reportDetailedSubType" label="Шаблон детального отчета">
          <b-form-select id="reportDetailedSubType" v-model="doctor.reportDetailedSubType" :options="reportDetailedSubTypeOptions" @change="changeReportDetailedSubType" :select-size="1"></b-form-select>
        </b-form-group>
        <b-form-group id="reportGeneralSubType" label-for="reportGeneralSubType" label="Шаблон обобщенного отчета">
          <b-form-select id="reportGeneralSubType" v-model="doctor.reportGeneralSubType" :options="reportGeneralSubTypeOptions" @change="changeReportGeneralSubType" :select-size="1"></b-form-select>
        </b-form-group>
        <b-form-group id="reportConciseSubType" label-for="reportConciseSubType" label="Шаблон сокращенного отчета">
          <b-form-select id="reportConciseSubType" v-model="doctor.reportConciseSubType" :options="reportConciseSubTypeOptions" @change="changeReportConciseSubType" :select-size="1"></b-form-select>
        </b-form-group>
        <b-form-group id="reportPatientSubType" label-for="reportPatientSubType" label="Шаблон отчета пациента">
          <b-form-select id="reportPatientSubType" v-model="doctor.reportPatientSubType" :options="reportPatientSubTypeOptions" @change="changeReportPatientSubType" :select-size="1"></b-form-select>
        </b-form-group>
        <b-form-group id="reportGraphicSubType" label-for="reportGraphicSubType" label="Шаблон графического отчета">
          <b-form-select id="reportGraphicSubType" v-model="doctor.reportGraphicSubType" :options="reportGraphicSubTypeOptions" @change="changeReportGraphicSubType" :select-size="1"></b-form-select>
        </b-form-group>
      </b-tab>
    </b-tabs>
    <b-modal id="specializationModal" hide-footer size="lg" title="Специализация">
      <SpecializationEdit @save-success="afterSpecializationSave" v-bind:error="error" ></SpecializationEdit>
    </b-modal>

    <b-modal id="clinicModal" hide-footer size="lg" title="Клиника">
      <ClinicEdit @save-success="afterClinicSave" v-bind:error="error" ></ClinicEdit>
    </b-modal>

  </div>
</template>

<script>
import {host} from "@/constants";
import Loader from "@/components/Loader";
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import SpecializationEdit from "@/components/admin/doctors/SpecializationEdit";
import ClinicEdit from "@/components/admin/doctors/ClinicEdit";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ru';

const phoneMask = '+ # (###) ###-##-##'

export default {
  props: ['doctorId'],
  name: "DoctorEdit",
  computed: {
    doctor: {
      get() {
        return this.$store.getters.doctor(this.doctorId)
      }
    }
  },
  data() {
    return {
      loading: false,
      error: null,
      regionOptions: [],
      reportDetailedSubTypeOptions: [],
      reportGeneralSubTypeOptions: [],
      reportConciseSubTypeOptions: [],
      reportPatientSubTypeOptions: [],
      reportGraphicSubTypeOptions: [],
      clientReport: {
        reportType: null,
        reportLevel: null,
        reportView: null,
        excludeSeldomDisease: false,
        excludePriorityDisease: false,
        allDisease: false,
        excludeBlackParam: false,
        excludeBlackGreenParam: false,
        excludeLowProbabilityEvents: false,
        excludeMiddleProbabilityEvents: false,
        excludeCheckListDisease: false
      },
      allDiseasesPCC:false,
      diseasePccListOptions: [],
      diseasePccList: [],
      vMask: phoneMask,
      // localDoctor: this.doctor ? this.doctor : {
      //   firstname: '',
      //   middlename: '',
      //   lastname: '',
      //   genderCode: 1,
      //   regionCode: 6611,
      //   password: '',
      //   phoneNumber: '',
      //   email: '',
      //   activationDate: new Date(),
      //   activationDaysCount: null,
      //   doctorComment: ''
      // },
//      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // The configuration of the editor.
      },
      genderOptions: [
        {
          value: 1,
          text: "Мужской"
        },
        {
          value: 2,
          text: "Женский"
        }
      ],
      clinicOptions: [],
      clinics: [],
      specializationOptions: [],
      specializations: [],
      doctorSessionInfo : {
        sessionCount: null,
        lastSessionDate: null,
        patientCount: null,
        reportCount: null,
        paramCount: null,
      },
      normalizeClinics(node) {
        return {
          id: node.clinicId,
          label: node.clinicName
        }
      },
      normalizeSpecializations(node) {
        return {
          id: node.doctorSpecializationId,
          label: node.doctorSpecializationName
        }
      }
    }
  },
  filters: {
    mainRussiaRegions: function (options) {
      let result = []
      for (let o of options) {
        if (o.parentRegionId === 1286 && o.isMain === true) {
          result.push(o)
        }
      }

      return result
    }
  },
  components: {
    Loader, Treeselect, SpecializationEdit, ClinicEdit, DatePicker
  },
  methods: {
    toogleExcludeBlackParam(checked) {
      if (checked) {
        this.clientReport.excludeBlackGreenParam = false
      }
      this.saveClientReport()
    },
    toogleExcludeBlackGreenParam(checked) {
      if (checked) {
        this.clientReport.excludeBlackParam = false
      }
      this.saveClientReport()
    },
    toogleExcludeLowProbabilityEvents(checked) {
      if (checked) {
        this.clientReport.excludeMiddleProbabilityEvents = false
      }
      this.saveClientReport()
    },
    toogleExcludeMiddleProbabilityEvents(checked) {
      if (checked) {
        this.clientReport.excludeLowProbabilityEvents = false
      }
      this.saveClientReport()
    },
    saveClientReport() {
      fetch(host + '/rest/patients/' + this.doctor.clientId + '/clientreport', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(this.clientReport),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode == 0) {

              //this.$messageToast('Настройки отчетов')

            } else {

              this.$errorToast(json.resultCode + ' ' + json.message)
            }
            this.loading = false
          }).catch(error => {
        this.$errorToast(error)
      })
    },
    onContext(ctx) {
      console.log(ctx.selected)
    },
    clickBackButton () {
      this.$router.go(-1)
    },
    async changeReportDetailedBlock(checked) {
      this.doctor.reportDetailedBlock = checked
      await this.saveDoctorSettings()
    },
    async changeReportGeneralBlock(checked) {
      this.doctor.reportGeneralBlock = checked
      await this.saveDoctorSettings()
    },
    async changeReportConciseBlock(checked) {
      this.doctor.reportConciseBlock = checked
      await this.saveDoctorSettings()
    },
    async changeReportPatientBlock(checked) {
      this.doctor.reportPatientBlock = checked
      await this.saveDoctorSettings()
    },
    async changeReportGraphicBlock(checked) {
      this.doctor.reportGraphicBlock = checked
      await this.saveDoctorSettings()
    },
    async changeReportPreBlock(checked) {
      this.doctor.reportPreBlock = checked
      await this.saveDoctorSettings()
    },
    async changeQuestionaryBlock(checked) {
      this.doctor.questionaryBlock = checked
      await this.saveDoctorSettings()
    },
    async changeParamPrescribedBlock(checked) {
      this.doctor.paramPrescribedBlock = checked
      await this.saveDoctorSettings()
    },
    async changeReportConfigBlock(checked) {
      this.doctor.reportConfigBlock = checked
      await this.saveDoctorSettings()
    },
    async changeQuestionaryTabGeneralEnable(checked) {
      this.doctor.questionaryTabGeneralEnable = checked
      await this.saveDoctorSettings()
    },
    async changeQuestionaryTabReproductEnable(checked) {
      this.doctor.questionaryTabReproductEnable = checked
      await this.saveDoctorSettings()
    },
    async changeQuestionaryTabComplaintsEnable(checked) {
      this.doctor.questionaryTabComplaintsEnable = checked
      await this.saveDoctorSettings()
    },
    async changeQuestionaryTabChronicEnable(checked) {
      this.doctor.questionaryTabChronicEnable = checked
      await this.saveDoctorSettings()
    },
    async changeQuestionaryTabDrugEnable(checked) {
      this.doctor.questionaryTabDrugEnable = checked
      await this.saveDoctorSettings()
    },
    async changeQuestionaryTabHeredityEnable(checked) {
      this.doctor.questionaryTabHeredityEnable = checked
      await this.saveDoctorSettings()
    },
    async changeAlternativeScaleBlock(checked) {
      this.doctor.alternativeScaleBlock = checked
      await this.saveDoctorSettings()
    },
    async changeOnlyOMSParams(checked) {
      this.doctor.onlyOMSParams = checked
      await this.saveDoctorSettings()
    },
    async changeDebugDoctor(checked) {
      this.doctor.debugDoctor = checked
      await this.saveDoctorSettings()
    },
    async toogleReportParamRecommendBlock(checked) {
      this.doctor.reportParamRecommendBlock = checked
      await this.saveDoctorSettings()
    },
    async toogleReportParamRecommendMetabolomicBlock(checked) {
      this.doctor.reportParamRecommendMetabolomicBlock = checked
      await this.saveDoctorSettings()
    },
    async changeShowCoefDReportGeneral(checked) {
      this.doctor.showCoefDReportGeneral = checked
      await this.saveDoctorSettings()
    },
    async changeShowCoefDReportConcise(checked) {
      this.doctor.showCoefDReportConcise = checked
      await this.saveDoctorSettings()
    },
    async changeReportDetailedSubType(newVal) {
      this.doctor.reportDetailedSubType = newVal
      await this.saveDoctorSettings()
    },
    async changeReportGeneralSubType(newVal) {
      this.doctor.reportGeneralSubType = newVal
      await this.saveDoctorSettings()
    },
    async changeReportConciseSubType(newVal) {
      this.doctor.reportConciseSubType = newVal
      await this.saveDoctorSettings()
    },
    async changeReportPatientSubType(newVal) {
      this.doctor.reportPatientSubType = newVal
      await this.saveDoctorSettings()
    },
    async changeReportGraphicSubType(newVal) {
      this.doctor.reportGraphicSubType = newVal
      await this.saveDoctorSettings()
    },
    async saveDoctorSettings() {
      this.loading = true
      let rs = await fetch(host + '/rest/doctors', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify(this.doctor),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
      let json = await rs.json()

      this.loading = false

      if (json.resultCode != 0) {
        this.raiseError(json)

        return
      }

    },
    async onSubmit() {
      this.loading = true
      let method = 'POST'
      if (this.doctorId) {
        method = 'PUT'
      }
      let rs = await fetch(host + '/rest/doctors', {
        method: method,
        credentials: 'include',
        body: JSON.stringify(this.doctor),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
      let json = await rs.json()

      if (json.resultCode != 0) {
        this.raiseError(json)
        this.loading = false
        return
      }

      let localDocId = this.doctorId
      if (!this.doctorId) {
        localDocId = json.entityId
      }
      json = await this.updateDoctorClinics(localDocId)
      if(json) {
        this.loading = false
        return
      }
      json = await this.updateDoctorSpecializations(localDocId)
      if(json) {
        this.loading = false
        return
      }

      this.$router.push('/doctors')

    },
    raiseError(json) {
      this.$bvToast.toast(json.resultCode + ' ' + json.message, {
        title: `Ошибка`,
        toaster: 'b-toaster-top-center',
        solid: true,
        variant: 'danger',
        noAutoHide: true,
        appendToast: true
      })
    },
    async updateDoctorClinics(localDocId) {
      let rs = await fetch(host + '/rest/doctors/' + localDocId + '/clinics', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({doctorClinics:this.clinics}),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
      let json = await rs.json()

      if (json.resultCode != 0) {
        this.raiseError(json)
        return json
      }

      return null
    },
    async updateDoctorSpecializations(localDocId) {
      let rs = await fetch(host + '/rest/doctors/' + localDocId + '/specializations', {
        method: 'PUT',
        credentials: 'include',
        body: JSON.stringify({doctorSpecializations:this.specializations}),
        headers: {'Content-Type': 'application/json; charset=utf-8'}
      })
      let json = await rs.json()

      if (json.resultCode != 0) {
        this.raiseError(json)
        return json
      }

      return null
    },
    fetchDictsData() {
      fetch(host + '/rest/dicts/region')
          .then(response => response.json())
          .then(json => {
            this.regionOptions = json.options
            this.loading = false
          })
      fetch( host + '/rest/clinics', {
        credentials: 'include'
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode === 0) {
              this.clinicOptions = json.clinics
            }
            this.loading = false
          })
      fetch( host + '/rest/specializations', {
        credentials: 'include'
      }).then(response => response.json())
          .then(json => {
            if (json.resultCode === 0) {
              this.specializationOptions = json.specializations
            }
            this.loading = false
          })
      if (this.doctorId) {
        fetch( host + '/rest/doctors/'+ this.doctorId + '/clinics', {
          credentials: 'include'
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode === 0) {
                this.clinics = json.doctorClinics
              }
              this.loading = false
            })
        fetch( host + '/rest/doctors/'+ this.doctorId + '/specializations', {
          credentials: 'include'
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode === 0) {
                this.specializations = json.doctorSpecializations
              }
              this.loading = false
            })
        fetch( host + '/rest/doctors/'+ this.doctor.clientId + '/sessions', {
          credentials: 'include'
        }).then(response => response.json())
            .then(json => {
              if (json.resultCode === 0) {
                this.doctorSessionInfo = json.doctorSessionInfo
              }
              this.loading = false
            })
      }
      fetch(host + '/rest/dicts/reportSubtype/1', {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.reportDetailedSubTypeOptions = json.options
            this.loading = false
          })
      fetch(host + '/rest/dicts/reportSubtype/2', {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.reportGeneralSubTypeOptions = json.options
            this.loading = false
          })
      fetch(host + '/rest/dicts/reportSubtype/3', {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.reportConciseSubTypeOptions = json.options
            this.loading = false
          })
      fetch(host + '/rest/dicts/reportSubtype/4', {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.reportPatientSubTypeOptions = json.options
            this.loading = false
          })
      fetch(host + '/rest/dicts/reportSubtype/5', {
        credentials: 'include'
      })
          .then(response => response.json())
          .then(json => {
            this.reportGraphicSubTypeOptions = json.options
            this.loading = false
          })
    },
    addClinic() {
      this.$bvModal.show('clinicModal')
    },
    afterClinicSave() {
      this.$bvModal.hide('clinicModal')
      this.loading = true
      this.fetchDictsData()
    },
    addSpecialization() {
      this.$bvModal.show('specializationModal')
    },
    afterSpecializationSave() {
      this.$bvModal.hide('specializationModal')
      this.loading = true
      this.fetchDictsData()
    }

  },
  watch: {
    'clientReport.reportType': function(newReportType)
    {
      if (!this.examinationResultsSettingsLoading)
        this.saveClientReport()
    },
    'clientReport.reportLevel': function(newReportLevel)
    {
      if (!this.examinationResultsSettingsLoading) this.saveClientReport()
    },
    'clientReport.reportView': function(newReportView)
    {
      if (!this.examinationResultsSettingsLoading) this.saveClientReport()
    },
    'clientReport.excludeSeldomDisease': function(newExcludeSeldomDisease)
    {
      if (!this.examinationResultsSettingsLoading) this.saveClientReport()
    },
    'clientReport.excludePriorityDisease': function(newExcludePriorityDisease)
    {
      if (!this.examinationResultsSettingsLoading) this.saveClientReport()
    },
    'clientReport.allDisease': function(newReportType) {
      if (!this.examinationResultsSettingsLoading) {
        this.saveClientReport()
        if (!newReportType && (!this.diseasePccList || this.diseasePccList.length == 0)) {
          this.diseasePccList = []
          this.diseasePccListOptions.forEach(el => {this.diseasePccList.push(el.value)})
          this.saveDiseaseRcc(this.diseasePccList)
        }
      }
    },
    'clientReport.excludeCheckListDisease': function(newExcludePriorityDisease)
    {
      if (!this.examinationResultsSettingsLoading) this.saveClientReport()
    }
  },
  mounted() {

    fetch(host + '/rest/patients/' + this.doctor.clientId + '/clientreport' , {
      credentials: "include"
    })
        .then(response => response.json())
        .then(json => {
          this.clientReport = json.clientReport

        }).catch(error => {
      console.log(error)

    })


    this.fetchDictsData()
  }
}
</script>

<style scoped>

</style>